$desktop: 1460px;
$medium: calc($desktop * 3 / 4); // 1095px
$tablet: calc($desktop / 2); //730px
$mobile: 600px;
$mobileMinWidth: 373px;
$desktopInterestButtonWidth: 350px;

@mixin for-size($size) {
  @if $size == small-mobile {
    @media (max-width: 530px) {
      @content;
    }
  } @else if $size == mobile {
    @media (max-width: 730px) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: 950px) {
      @content;
    }
  } @else if $size == small-laptop {
    @media (max-width: 1095px) {
      @content;
    }
  } @else if $size == laptop {
    @media (max-width: 1160px) {
      @content;
    }
  } @else if $size == small-desktop {
    @media (max-width: 1460px) {
      @content;
    }
  } @else if $size == desktop {
    @media (max-width: 1520px) {
      @content;
    }
  } @else if $size == lg-desktop {
    @media (max-width: 1920px) {
      @content;
    }
  }
}
