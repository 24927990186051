:root {
  --white: #fff;

  --black: #000;

  --gray: #f9f9f9; // $primaryBackground
  --gray-0: #f0f0f0; //$lightBackground
  --gray-1: #f1f1f1;
  --gray-2: #d2d2d2;

  --orange: #ff9900;
  --orange-1: #ffebd9;
  --orange-2: #fff5ed;

  --indian-red: #cd5c5c;

  --red: #bf0b00;
  --red-0: #fff0f0;
  --red-1: #a70000;

  --green: #00b207;
  --green-0: #f1fff0;
  --green-2: #70d66e;

  --dark-gray: #5555; // $label-color
  --dark-gray-1: #555555; // $label-color

  --aquamarine: #9ae9df;
  --aquamarine-1: #82dce1;

  --purple: #d39cd4;
  --purple-1: #9e71d8;

  --yellow: #ecd146;
  --yellow-1: #f1c40f;
  --yellow-2: #f2e9c3;

  --pink: #e58570;

  --blue: #65aaea;
}
