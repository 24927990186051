.content {
  margin: 0 auto;

  border-radius: 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  position: relative;
  z-index: 50;

  text-align: center;

  /* size props */

  &_sizeSm {
    max-width: 410px;
    height: auto;

    padding: 50px 30px;
  }

  &_sizeMd {
    max-width: 410px;
    height: 300px;

    padding: 50px 30px;
  }

  &_sizeLg {
    padding: 20px;
  }

  /* color props */

  &_colorWhite {
    background-color: var(--white);

    .close {
      background-color: var(--white);
    }
  }

  &_colorGray {
    background-color: var(--gray);
  }

  /* components */

  &__heading {
    font: var(--typo-t1);
    padding: 0 10px;
  }

  &__description {
    font: var(--typo-t3);
    margin-bottom: 10px;
    opacity: 0.5;
    padding: 0 10px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 16px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
}
