@import 'src/styles/constants.module.scss';

.cookies {
  position: fixed;
  bottom: 0;
  z-index: 150;

  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  gap: 15px;

  max-width: 980px;

  padding: 30px;
  margin-inline: auto;
  inset-inline: 0;
  background-color: var(--white);

  border-top-right-radius: 30px;
  border-top-left-radius: 30px;

  box-shadow: 0px 10px 50px 0px #0000000d;

  @include for-size(mobile) {
    flex-direction: column;
    align-items: center;
    gap: 25px;

    padding-bottom: 45px;
  }

  &__heading,
  &__controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__heading {
    align-items: flex-start;
    max-width: 70%;

    .title {
      margin: 0;
      padding: 0;
      font: var(--typo-t1);
      text-align: left;
    }

    .message {
      width: 90%;
      opacity: 0.5;
    }

    @include for-size(mobile) {
      max-width: 100%;
      gap: 25px;

      .title,
      .message {
        text-align: center;
        width: 100%;
        padding: 0 15px;
      }
    }
  }

  &__controls {
    justify-content: space-between;
    width: 150px;

    @include for-size(mobile) {
      width: 100%;
    }
  }
}

.link {
  background-color: transparent;
  text-decoration: underline;

  opacity: 0.7;
  color: var(--black);
}
