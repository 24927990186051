@import 'src/styles/constants.module.scss';

.link {
  position: relative;
  width: fit-content;
  margin: 0 auto 0 auto;
  z-index: 50;
  padding: 0px;

  cursor: pointer;

  &:hover {
    background-color: transparent !important;
  }
}

.logo {
  width: 260px;
  height: 46px;

  @include for-size(mobile) {
    width: 236px;
    height: 42px;
  }
}
