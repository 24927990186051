@import 'src/styles/constants.module.scss';

.nav {
  width: 100%;

  padding: 11px 30px;

  border-radius: 8px;

  position: absolute;

  &_wrapper {
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    width: 340px;

    margin: 0 auto;

    @include for-size(mobile) {
      width: auto;
    }
  }

  @media screen and (max-width: $medium) {
    position: relative;
    width: auto;
  }

  @include for-size(mobile) {
    justify-content: space-between;

    position: absolute;
    bottom: 0;
    margin-inline: auto;
    inset-inline: 0;

    background-color: var(--gray);

    border-radius: 50px;

    padding: 11px 40px;
  }
}

.link {
  color: #000000;
  opacity: 0.5;
}

.active {
  opacity: 1;
}
