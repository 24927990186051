@import 'src/styles/constants.module.scss';

@mixin left-reverse {
  @for $i from 0 through 4 {
    button:nth-child(#{$i}) {
      transition: transform 0.4s;
      transform: translateX(-(4 - $i) * 52px);
    }
  }
}

@mixin one-child($transform: 0px) {
  button {
    &:only-child {
      transition: transform 0.4s;
      transform: translateX($transform);
    }
  }
}

.speeddial {
  display: flex;
  position: relative;
  z-index: 50;

  .buttonClose {
    display: none;

    @include for-size(mobile) {
      display: flex;
    }
  }

  &_buttonMove {
    justify-content: flex-end;
    gap: 10px;

    &_open {
      width: 100%;
      position: absolute;
      background-color: var(--white);

      .buttonOpen {
        z-index: -1;
        opacity: 0;

        padding: 0px;
        transform: translateX(-150px);
        transition:
          transform 0.3s,
          opacity 0.2s,
          z-index 0.2s 0.2s;
        position: absolute;
      }

      .title {
        opacity: 1;

        .buttonClose {
          position: relative;
        }
      }

      .actions {
        display: flex;
        flex-direction: row-reverse;
        width: auto;
        opacity: 1;
        transform: translateX(0px);
        transition:
          transform 0.3s,
          opacity 0.2s;
      }
    }

    &_close {
      .buttonOpen {
        z-index: 51;
        transform: translateX(0px);
        transition:
          transform 0.3s,
          opacity 0.2s;
        width: 0.2s 0.5s;
      }

      .title {
        opacity: 0;
        position: absolute;
        left: 0;
        padding: 10px;

        @include for-size(mobile) {
          padding-top: 0;
          padding-left: 0;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .actions {
        display: flex;
        opacity: 0;
        position: absolute;
        transform: translateX(140px);
        transition: transform 0.3s;
      }
    }
  }

  //----------------------------
  &_actionMoveReverse {
    min-width: 48px;

    .buttonOpen {
      z-index: 51;
    }

    .title {
      opacity: 0;
      position: absolute;
      left: 0;
      padding: 10px;

      @include for-size(mobile) {
        padding-top: 0;
        padding-left: 0;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .actions {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      width: 0px;
      opacity: 1;
      transition: opacity 0.5s;
      position: relative;

      button {
        position: absolute;
        z-index: -5;
        transition: transform 0.4s;
        transform: translateX(0px);
      }
    }

    &:hover {
      @media screen and (min-width: $tablet) {
        width: 350px;
        position: absolute;
        background-color: var(--white);
        z-index: 58;
        justify-content: space-between;

        .title {
          opacity: 1;
          transition: opacity 0.4s;
        }

        .buttonOpen {
          opacity: 0;
          z-index: -5;
          width: 0px;
          padding: 0px;
        }

        .actions {
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          width: 208px;
          opacity: 1;

          button {
            position: absolute;
            z-index: 50;
          }

          @for $i from 0 through 4 {
            button:nth-child(#{$i}) {
              transition: transform 0.4s;
              transform: translateX(-(4 - $i) * 52px);
            }
          }
        }
      }
    }

    &_open {
      width: 350px;
      position: absolute;
      background-color: var(--white);
      z-index: 58;
      justify-content: space-between;

      .title {
        opacity: 1;
        transition: opacity 0.4s;
      }

      .buttonOpen {
        opacity: 0;
        z-index: -5;
        width: 0px;
        padding: 0px;
      }

      .actions {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        width: 208px;
        opacity: 1;

        button {
          position: absolute;
          z-index: 50;
        }

        @for $i from 0 through 4 {
          button:nth-child(#{$i}) {
            transition: transform 0.4s;
            transform: translateX(-(4 - $i) * 52px);
          }
        }
      }
    }
  }
}
