.Toastify__toast {
  padding: 10px;
  border-radius: 24px;

  @include for-size(small-mobile) {
    border-radius: 0px;
  }
}

.Toastify__toast-icon {
  width: 38px;
}

.Toastify__progress-bar {
  height: 2px;
}
