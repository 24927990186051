/* stylelint-disable color-no-hex */
/* stylelint-disable selector-class-pattern */

.success,
.error,
.warning {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.error {
  background-color: var(--red-0);
}

.error .icon {
  color: var(--red);
}

.success {
  background-color: var(--green-0);
}

.success .icon {
  color: var(--green);
}

.warning {
  background-color: var(--yellow-2);
}

.warning .icon {
  color: var(--yellow-1);
}
