@import 'src/styles/constants.module.scss';

.container {
  @include container;
  height: calc(100% - 6rem);

  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font: 400 128px/180px var(--font-family);
  }

  p {
    font: 400 26px/36px var(--font-family);
  }

  @include for-size(mobile) {
    justify-content: flex-start;
    padding-top: 80px;

    h2 {
      font: 400 96px/134px var(--font-family);
    }

    p {
      font: 400 20px/28px var(--font-family);
    }
    &.maintenance {
      h2 {
        font-size: 50px;
        line-height: 70px;
      }
    }
  }
}
