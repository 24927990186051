.list {
  position: absolute;
  width: 100%;
  z-index: 50;

  margin: 0;
  padding: 0;

  max-height: 250px;
  overflow-y: scroll;
}

.list::-webkit-scrollbar {
  width: 7px;

  background-color: var(--grey-2);

  border-bottom-right-radius: 12px;
}

.list::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: var(--dark-gray-1);
}

.item {
  cursor: pointer;

  max-height: 48px;
  padding: 12px 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  border-top: 1px solid var(--gray-1);
  margin: 0px auto;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: var(--gray-1);
    outline: none;
  }

  &:last-child {
    border-radius: 0 0 24px 24px;
  }

  &__button {
    justify-content: center;
  }

  &_hover {
    background-color: var(--gray-1);
  }

  &_disable {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: transparent;
      outline: none;
    }
  }
}

.heading {
  font: var(--typo-l1);
}
