:root {
  /* Text-1 */
  --font-size-t1: 26px;
  --line-height-t1: 36.4px;

  /* Text-1-large */
  --font-size-t1-large: 36px;
  --line-height-t1-large: 50.4px;

  /* Caption-1 */
  --font-size-c1: 24px;
  --line-height-c1: 33.6px;

  /* Caption-2 */
  --font-size-c2: 20px;
  --line-height-c2: 28px;

  /* Text-2 */
  --font-size-t2: 18px;
  --line-height-t2: 25.2px;

  /* Text-3 */
  --font-size-t3: 16px;
  --line-height-t3: 22.4px;

  /* Label-1 */
  --font-size-l1: 14px;
  --line-height-l1: 19.6px;

  /* Label-2 */
  --font-size-l2: 12px;
  --line-height-l2: 16.8px;

  /* Typography */
  --typo-t1-large: 400 var(--font-size-t1-large) / var(--line-height-t1-large)
    var(--font-family);

  --typo-t1: 400 var(--font-size-t1) / var(--line-height-t1) var(--font-family);
  --typo-t2: 400 var(--font-size-t2) / var(--line-height-t2) var(--font-family);
  --typo-t3: 400 var(--font-size-t3) / var(--line-height-t3) var(--font-family);

  --typo-c1: 300 var(--font-size-c1) / var(--line-height-c1) var(--font-family);
  --typo-c2: 300 var(--font-size-c2) / var(--line-height-c2) var(--font-family);

  --typo-l1: 400 var(--font-size-l1) / var(--line-height-l1) var(--font-family);
  --typo-l2: 400 var(--font-size-l2) / var(--line-height-l2) var(--font-family);
}
