@import 'src/styles/constants.module.scss';

.header {
  @include container;
  background-color: hsl(0, 0%, 100%);
  min-height: 3.25rem;
  position: relative;
  z-index: 30;
  margin-top: 0.5rem;

  &__show {
    @include for-size(mobile) {
      display: block;
    }
  }

  &__brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    min-height: 3.25rem;
  }

  @include for-size(mobile) {
    min-height: calc(3.25rem + 48px + 20px);
  }
}

.settings {
  transition: all 0.3s;
  position: relative;
  cursor: pointer;

  padding: 0;

  &_link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_icon {
    opacity: 0.6;
    transition: all 0.3s;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}
