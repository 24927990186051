@import 'src/styles/constants.module.scss';

.content {
  background-color: var(--gray);

  position: relative;
  z-index: 50;

  h3 {
    font: var(--typo-c1);
    font-weight: 400;
  }

  p {
    font: var(--typo-t3);
    margin-bottom: 5px;
    opacity: 0.5;
  }

  &_column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    gap: 20px;

    margin: 0 auto;

    .heading {
      gap: 20px;
    }
  }

  &_row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: min-content;
      min-width: 125px;
    }

    .heading {
      width: 70%;
      gap: 5px;
    }

    @include for-size(mobile) {
      flex-direction: column;
      gap: 25px;

      button {
        width: 100%;
        min-width: auto;
      }

      .heading {
        gap: 25px;
        width: 100%;
      }
    }
  }

  &_md {
    max-width: 410px;
    padding: 50px 30px;

    border-radius: 30px;
  }

  &_xl {
    padding: 25px 30px;
    margin: 0 auto;

    width: 100%;
    max-width: 980px;
    height: auto;

    border-radius: 24px;
  }
}

.heading {
  display: flex;
  flex-direction: column;

  gap: 15px;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
}
