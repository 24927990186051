@import 'src/styles/constants.module.scss';

.toast {
  &_body {
    display: grid;
    grid-template-columns: 40px auto;
    gap: 15px;

    padding: 0;

    div {
      width: auto;
      margin-right: 0px;
    }

    h5 {
      font: var(--typo-t3);
      font-weight: 500;
      margin-bottom: 5px;
      color: black;
    }

    p {
      font: var(--typo-l1);
      color: black;
      opacity: 0.4;
    }
  }
}
