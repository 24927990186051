@import 'src/styles/constants.module.scss';

.control {
  height: 36px;
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: inherit;

  width: 100%;
  max-width: 350px;

  @include for-size(small-laptop) {
    max-width: 100%;
    min-width: 250px;
  }
}

.control .input {
  padding-right: 4.5em;
  padding-left: 1.5em;
}

.input {
  width: 100%;

  height: 36px;
  display: flex;

  border-radius: 12px;

  background-color: var(--gray);

  border: none;
  box-shadow: none;
  outline: none;

  &::placeholder {
    font-size: 14px;
    font-weight: 430;

    color: var(--black);
    opacity: 0.6;

    -webkit-background-clip: text;
    background-clip: text;
  }

  &:focus,
  &:focus-visible,
  &:active,
  &:hover {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: var(--gray-0);
    transition: background-color 0.4s;
  }
}

.icon {
  position: absolute;
  z-index: 4;
  right: 5px;
  top: 0;

  height: 100%;
  width: 3.5rem;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);

  color: var(--black);

  &__clear {
    opacity: 0.5;
    border: none;
    cursor: pointer;
    background: transparent;

    &:hover {
      background: transparent;
      opacity: 1;
    }
  }

  &__search {
    display: block;
    grid-column-start: 2;
    opacity: 0.5;
    cursor: pointer;
  }
}

.error .input {
  border: 1px solid var(--red-1);
}

.message {
  display: block;
  position: absolute;
  left: 1.5em;
  margin-top: 4px;
  color: var(--red-1);

  bottom: -7px;

  padding: 0 5px;

  font: var(--typo-l2);
  font-size: 10px;
  background-color: var(--white);
}
