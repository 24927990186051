.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 100%;

  &__overlay {
    background-color: rgba(0, 0, 0, 0.294);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
  }
}
