//@import '~bulma/sass/grid/columns';
@import './constants.module.scss';
@import 'mixins';
@import 'typography';
@import 'colors';
@import 'breakpoints';
@import 'toastify';

$navbar-breakpoint: 100000px;

.navbar-menu {
  width: min(600px, 100%);
  position: absolute;
  right: 0;
}

$input-radius: 50px;
$input-border-color: $primaryBackground;
$label-weight: 500;

$box-background-color: $primaryBackground;
$box-color: #000;
$box-padding: 30px;
$box-radius: 24px;
$box-shadow: none;
$tabs-toggle-link-border-color: $primaryBackground;
$tabs-toggle-link-hover-border-color: $primaryBackground;
$tabs-toggle-link-active-color: #000;
$tabs-toggle-link-active-border-color: $primaryBackground;
$tabs-toggle-link-active-background-color: $primaryBackground;

/* TODO: удалить импорт булмы */
@import '~bulma/bulma';

html,
body {
  padding: 0;
  margin: 0;
  font-style: normal;
  background-color: #ffffff;
  color: #000000;
  height: 100%;
  font: var(--typo-t3);

  &::-webkit-scrollbar {
    background: transparent;
    width: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

main {
  width: 100%;
  height: 100vh;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.is-rounded {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
  }
}
