@import 'src/styles/constants.module.scss';

.label {
  // font-size: 12px;
  padding-left: 11px;
  display: block;

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }

  &__icon {
    position: relative;
    padding-left: 0px;
    height: 22px;

    span {
      position: absolute;
      left: -35px;
      transition: left, 0.5s;
    }
  }
}

.field {
  padding: 0;

  position: relative;

  @include for-size(mobile) {
    margin-bottom: 0;
  }

  /* variants */

  &_group {
    &:first-child {
      .background {
        border-bottom-left-radius: 45px;
        border-top-left-radius: 45px;
      }
    }

    &:last-child {
      .background {
        border-bottom-right-radius: 45px;
        border-top-right-radius: 45px;
      }
    }

    &:not(:last-child) {
      .background {
        &::after {
          content: '|';
          margin-left: 0.1rem;
          font-size: 1em;
          position: absolute;
          opacity: 0.2;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }

    &:not(::nth-child(2)) {
      .background {
        border-radius: 50px;
      }
    }
  }

  &_single {
    div {
      border-radius: 50px;
      &::after {
        content: '' !important;
      }
    }
  }

  /* position */

  &_positionCenter {
    label {
      text-align: center;
      font: var(--typo-l2);
      padding-left: 0px;
    }

    input {
      text-align: center;
      &::placeholder {
        text-align: center;
        color: var(--gray-2);
      }
    }
  }

  &_positionLeft {
    input {
      &::placeholder {
        text-align: left;
      }
    }
  }

  &_positionRight {
    label {
      text-align: right;
      padding-right: 0px;
    }
    input {
      &::placeholder {
        text-align: left;
      }
    }
  }

  &_directionRow {
    display: grid;
    grid-template-columns: 200px 349px;
    align-items: center;
    gap: 20px;

    .label {
      font: var(--typo-t3);
    }
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: inherit;
}

.control_hasIcon .input {
  padding-right: 2.5em;
  padding-left: 2.5em;
}

.control_hasIcon .icon {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}

.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.background {
  height: 48px;
  padding: 0.3rem;
  width: 100%;
  display: block;
  box-sizing: border-box;
  background-color: var(--gray);

  &::after {
    content: '';
  }

  &__error {
    &::before {
      border: 1px solid var(--red-1);
      border-radius: 45px;

      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &::after {
      content: '' !important;
    }
  }
}

.input {
  border: none;

  border-radius: 50px;

  box-shadow: none;
  background-color: var(--gray);
  transition: background-color 0.4s;

  max-width: 100%;
  width: 100%;

  display: inline-flex;
  align-items: center;

  font: var(--typo-t3);

  justify-content: flex-start;

  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  margin: 0;
  position: relative;
  vertical-align: top;

  &::placeholder {
    color: var(--gray-2);
  }

  &:focus,
  &:focus-visible,
  &:active,
  &:hover {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: var(--gray-0);
    transition: background-color 0.4s;
  }
}

.error {
  display: block;
  position: absolute;
  left: 20px;
  margin-top: 4px;
  color: var(--red-1);

  bottom: -7px;

  padding: 0 5px;

  font: var(--typo-l2);
  background-color: var(--white);
}
