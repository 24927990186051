@import 'src/styles/constants.module.scss';

.field {
  position: relative;
}

.select {
  &__container {
    position: relative;

    background-color: var(--gray);
  }

  &__header {
    cursor: pointer;

    max-height: 48px;
    padding: 0.3rem;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @include for-size(mobile) {
      margin: 0 auto;
    }
  }

  &__control {
    position: absolute;
    right: 10px;
  }
}

.group .select__container {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.single .select__container {
  border-radius: 24px;
}

.open .select__container {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.open ul {
  border-radius: 0 0 24px 24px;
  background-color: var(--gray);
  opacity: 1;
  transition: all 0.3s 0.2s;
}

.label {
  padding-left: 11px;
  color: #555;
  font: var(--typo-t3);

  display: block;

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }

  @include for-size(mobile) {
    padding-left: 0px;
    text-align: center;
    font: var(--typo-l2);
  }
}

.input {
  border: none;
  outline: none;

  border-radius: 50px;

  box-shadow: none;
  background-color: var(--gray);
  transition: background-color 0.4s;

  max-width: 100%;
  width: 100%;

  display: inline-flex;
  align-items: center;

  font: var(--typo-t3);

  justify-content: flex-start;

  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  margin: 0;
  position: relative;
  vertical-align: top;

  padding-right: 30px;

  &:focus,
  &:focus-visible,
  &:active,
  &:hover {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: var(--gray-0);
    transition: background-color 0.4s;
  }
}
