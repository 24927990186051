.container {
  display: inline-flex;
  gap: 10px;

  align-items: center;
}

.checkbox .round {
  position: relative;
}

.label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: block;

  &:after {
    border: 2px solid #1c1b1f;
    border-top: none;
    border-right: none;
    content: '';

    height: 6px;
    left: 6.5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 11px;
  }
}

.input {
  visibility: hidden;
  display: none;
  opacity: 0;
}

.content {
  display: grid;
  align-items: center;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  gap: 4px;

  a {
    color: var(--dark-gray-1);
  }
}

.checkbox .round input[type='checkbox']:checked + label {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

.checkbox .round input[type='checkbox']:checked + .label:after {
  opacity: 1;
}

/* size */

.small .label {
  height: 20px;
  width: 20px;

  &::after {
    height: 5px;
    left: 5px;
    top: 6.2px;
    width: 10px;
  }
}

.small .content {
  font: var(--typo-l2);

  color: #929292;
}

.normal .content {
  font: var(--typo-t3);
}

/* error */

.error .content {
  color: var(--red-1);

  a {
    color: var(--red-1);
  }
}

.error .label {
  border-color: var(--red-1);
}
