@import 'media';

@mixin button-icon-hover {
  svg {
    opacity: 0.4;
    transition: opacity 0.4s;
  }

  &:hover {
    transition: opacity 0.4s;

    svg {
      transition: opacity 0.4s;
      opacity: 1;
    }
  }
}

// for style in component Input - [inputBackground]
@mixin one-input-radius {
  div {
    border-radius: 50px;
    &::after {
      content: '' !important;
    }
  }
}

@mixin modal-content {
  display: flex;
  flex-direction: column;
  height: 783px;
  overflow: hidden;
  margin: 10px 20px;
  position: relative;
  width: 410px;
  border-radius: 30px;
  background-color: var(--white);
  padding: 30px 30px 40px;
  gap: 15px;

  @media screen and (max-width: 730px) {
    margin: 10px auto;
    padding: 30px 25px 40px;
    height: 900px;
    width: calc(100% - 20px);
    max-width: 393px;
    min-width: 353px;
  }
}

/** for pages layout */
@mixin container($mt: 0px, $mb: 0px) {
  margin-top: $mt;
  margin-bottom: $mb;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: calc($mobile + 1px)) and (max-width: calc($tablet - 1px)) {
    width: $mobileMinWidth;
    max-width: $mobileMinWidth;
    min-width: $mobileMinWidth;
  }
  @media screen and (min-width: $tablet) and (max-width: calc($medium - 1px)) {
    width: $tablet;
    max-width: $tablet;
    min-width: $tablet;
  }
  @media screen and (min-width: $medium) and (max-width: calc($desktop - 1px)) {
    width: $medium;
    max-width: $medium;
    min-width: $medium;
  }
  @media screen and (min-width: $desktop) {
    width: $desktop;
    max-width: $desktop;
    min-width: $desktop;
  }
  @media screen and (max-width: $mobile) {
    width: $mobileMinWidth;
    max-width: $mobileMinWidth;
    min-width: $mobileMinWidth;
  }

  @media screen and (max-width: $mobileMinWidth) {
    width: 100%;
    max-width: $mobileMinWidth;
    min-width: $desktopInterestButtonWidth;
  }
}
