.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
  margin-left: 9px;
  border-radius: 12px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2e2e2;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 14px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 3px;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: black;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}
