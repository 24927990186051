.button {
  border-radius: 12px;
  height: 48px;
  transition: background-color 0.4s;
  border: none;
  box-shadow: none;

  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  white-space: nowrap;

  cursor: pointer;

  padding: calc(0.5em - 1px) 1em;
  font-size: 1rem;

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }

  &:focus-visible {
    border: none !important;
    box-shadow: none !important;
  }

  &:active {
    border: none !important;
    box-shadow: none !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &_variantLink {
    height: auto;

    font: var(--typo-l1);
    border-radius: 0;
    padding: 0;
  }

  &_sizeLg {
    width: 100%;
  }

  &_sizeM {
    width: 53px;
    height: 36px;
    border-radius: 20px;
    padding: 0.5rem;
  }

  &_sizeIcon {
    border-radius: 50%;
    width: 48px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: none;
    box-shadow: none;
    justify-content: center;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }

    &:focus-visible {
      border: none !important;
      box-shadow: none !important;
    }

    &:active {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &_sizeIconSm {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: none;
    box-shadow: none;
    justify-content: center;
  }

  &_colorBlack {
    background-color: #0a0a0a;
    border-color: transparent;
    color: #fff;

    transition:
      background-color 0.4s,
      color 0.3s;

    &:disabled {
      opacity: 0.5;
    }

    @media (hover: hover) {
      &:hover {
        color: black !important;
        background-color: var(--gray-1) !important;
        transition:
          background-color 0.4s,
          color 0.3s;
      }
    }
  }

  &_colorWhite {
    background-color: #fff;
    border-color: transparent;
    color: #0a0a0a;

    @media (hover: hover) {
      &:hover {
        color: black !important;
        background-color: var(--gray) !important;
        transition:
          background-color 0.4s,
          color 0.3s;
      }
    }
  }

  &_colorGrey {
    background-color: var(--gray-0);
    transition: background-color 0.4s;
    color: var(--black);
  }

  &_colorPrimary {
    background-color: var(--gray);
    color: var(--black);

    @media (hover: hover) {
      &:hover {
        background-color: var(--gray-0);
        transition: background-color 0.4s;
      }
    }
  }

  &__colorTransparent {
    background-color: transparent;
  }
}
